import React from "react"
import { Link } from "gatsby"
import { Result, Button } from "antd"
import styled from "styled-components"

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-result {
    margin: 0 auto;
    text-align: center;
    .ant-result-title {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 15px;
    }
    .ant-result-subtitle {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 400;
      font-size: 16px;
    }
    .ant-result-extra {
      a {
        font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
        font-weight: 500;
      }
    }
  }
`

const NotFound = () => {
  return (
    <PageContainer>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the path you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Go Home</Button>
          </Link>
        }
      />
    </PageContainer>
  )
}

export default NotFound
